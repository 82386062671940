import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import { Link } from 'gatsby';

const StyledHomeHero = styled.div`
    margin-bottom: -9em;
    margin-top: 2em;
    a {
        color: ${colors.white};
    }
    .hero-container {
        min-height: 100vh;
        background: radial-gradient(
            396.02% 100% at 100% 0%,
            #00bcd4 0%,
            #b29eb7 43.89%,
            #86d3e2 68.91%,
            #00bcd4 100%
        );
        color: ${colors.white};
        padding: 5.5em 1em 0em;
    }
    .star-mobile {
        z-index: 1;
        top: 30em;

        position: absolute;
    }
    .title {
        padding-right: 4em;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 0px;
        font-family: 'Montserrat';
    }
    p,
    h1 {
        font-weight: 700;
        font-size: 18px;
        color: ${colors.white};
        margin: 1.5em 0em;
        line-height: 22px;
    }
    .image-container {
        padding: 0.5em;
        position: relative;
        bottom: 11.5em;
        z-index: 2;
        .name {
            color: ${colors.pink};
            font-weight: 700;

            margin: 1.5em 0em 0.25em;
        }
        p,
        h1 {
            margin: 0;
            z-index: 200;
        }
        .main-image {
            z-index: 1;
        }
        .underlayer {
            z-index: 1;
            opacity: 0.7;
            filter: blur(41px);
            border-radius: 20px;
            position: absolute;
            top: 3em;
            left: 2em;
            width: 100%;
        }
    }
    .hero-container {
        .star-two {
            display: none;
        }
        .star {
            height: 350px;
            width: 350px;
            position: absolute;
            left: 0em;
            top: 0em;
            display: block;
        }
    }
    .sub {
        font-style: italic;
        font-family: 'Bodoni MT';
    }
    .squiggly-arrow {
        display: none;
    }
    @media ${device.tablet} {
        margin-bottom: 0em;
        display: flex;
        align-items: center;
        padding: 0em 4em 18em;
        z-index: 0;
        height: 100vh;
        justify-content: center;
        background: radial-gradient(
            396.02% 100% at 100% 0%,
            #00bcd4 0%,
            #b29eb7 43.89%,
            #86d3e2 68.91%,
            #00bcd4 100%
        );
        .title {
            font-size: 54px;
            line-height: 71px;
        }
        .hero-container {
            background: none;
        }
        .image-container {
            padding: 0.5em;
            position: relative;
            bottom: -8.5em;

            .squiggly-arrow {
                display: none;
                position: absolute;
                top: -12em;
                right: -16.4em;
                z-index: 3;
                width: 1346px;
            }
            .main-image {
                position: relative;
                width: 329px;
            }
        }
        .text-container {
            max-width: 300px;
            margin-right: 2em;
            margin-top: 20em;
            .title {
                font-size: 46px;
                width: 400px;
                line-height: 54px;
                padding-right: 0em;
            }
        }
    }
    .hero-container {
        max-width: 300px;
        position: relative;
        .star-two {
            display: block;
            position: absolute;

            width: 335px;
            top: 27em;
            left: 63em;
        }
        .star {
            position: absolute;
            width: 335px;
            top: 27em;
            left: 0em;
            height: 335.77px;
            z-index: 0;
        }
    }
    @media ${device.laptop} {
        margin-top: 0em;
        padding: 8em 4em 7em;
        z-index: 0;
        height: 88vh;
        min-height: 700px;
        position: relative;
        bottom: 00px;
        margin-bottom: 4em;
        justify-content: center;
        background: radial-gradient(
            396.02% 100% at 100% 0%,
            #00bcd4 0%,
            #b29eb7 43.89%,
            #86d3e2 68.91%,
            #00bcd4 100%
        );

        .hero-container {
            background: none;
        }
        .image-container {
            padding: 0.5em;
            position: relative;
            .squiggly-arrow {
                display: block;
                position: absolute;
                top: -10em;
                right: -16.4em;
                z-index: -3;
                width: 1346px;
            }
            .main-image {
                position: relative;

                width: 529px;
            }
        }
        .text-container {
            max-width: 700px;
            margin-right: 3em;
            margin-top: 7em;
            .title {
                width: 600px;
                padding-right: 0em;
                font-size: 54px;
                line-height: 71px;
            }
        }
    }
    .hero-container {
        max-width: 1500px;

        position: relative;
        .star-two {
            display: block;
            position: absolute;
            width: 635px;
            left: 63em;
        }
        .star {
            position: absolute;
            width: 635px;
            top: 27em;
            left: 30em;
            height: 635.77px;
        }
    }
    @media ${device.laptopL} {
        margin-bottom: 0em;
        height: 90vh;
        padding: 0em 4em 7em;
        .title {
            font-size: 59px;
            line-height: 71px;
        }
        .hero-container {
            background: none;
        }

        .image-container {
            .squiggly-arrow {
                top: -6em;
                right: -16.35em;
                width: 1346px;
            }
            .main-image {
                width: 529px;
            }
        }
        .text-container {
            margin-top: 12em;
            margin-right: 4em;
        }
    }
    .hero-container {
        .star-two {
            display: block;
            position: absolute;
            width: 635px;
            top: 32em;
            left: 63em;
        }
        .star {
            position: absolute;
            width: 635px;
            top: 32em;
            left: 30em;
            height: 635.77px;
        }
    }
    @media ${device.desktop} {
        margin-bottom: 0em;
        height: 85vh;
        padding: 0em 4em 7em;

        .title {
            font-size: 59px;
            line-height: 71px;
        }
        .hero-container {
            background: none;
        }

        .image-container {
            .squiggly-arrow {
                top: -10em;
                right: -16.35em;
                width: 1346px;
            }
            .main-image {
                width: 529px;
            }
        }
        .text-container {
            margin-top: 16em;
            margin-right: 8em;
        }
    }
    .hero-container {
        .star-two {
            display: block;
            position: absolute;
            width: 635px;
            top: 32em;
            left: 63em;
        }
        .star {
            position: absolute;
            width: 635px;
            top: 32em;
            left: 30em;
            height: 635.77px;
        }
    }
`;

export const HomeHero: React.FC = () => {
    return (
        <>
            <StyledHomeHero>
                <StaticImage
                    className="star-mobile mobile"
                    src="../images/svgs/white-star.svg"
                    placeholder="none"
                    quality={100}
                    alt="white star"
                />
                <div className="hero-container">
                    <div className="text-container">
                        <p className="title">
                            <span className="sub">Upgrade</span> your Healthcare, elevate your
                            Health
                        </p>
                        <h1 style={{ fontSize: '20px', color: `${colors.blueDark}` }}>
                            Personalized, holistic primary care in Kansas City, MO
                        </h1>
                        <p>
                            Unlimited visits to the physician and healthcare team in person,
                            virtual, SMS, phone. No co-pays or deductibles
                        </p>
                        <Link to="/contact-us/">
                            <Button color="white">
                                <span>Get in touch</span>{' '}
                                <StaticImage
                                    src="../images/svgs/arrow-right-white.svg"
                                    placeholder="none"
                                    quality={100}
                                    alt="white arrow point right"
                                />
                            </Button>
                        </Link>
                    </div>
                    <StaticImage
                        className="desktop star"
                        src="../images/svgs/white-star.svg"
                        placeholder="none"
                        quality={100}
                        alt="white star"
                    />
                    <StaticImage
                        className="desktop star-two"
                        src="../images/svgs/white-star.svg"
                        placeholder="none"
                        quality={100}
                        alt="white star"
                    />
                </div>

                <div className="image-container">
                    <StaticImage
                        className="squiggly-arrow"
                        src="../images/svgs/arrow-squiggle.svg"
                        placeholder="none"
                        quality={100}
                        alt="squiggly arrow"
                    />
                    <StaticImage
                        className="underlayer"
                        src="../images/girl-sitting.png"
                        placeholder="none"
                        quality={100}
                        alt="girl meditating"
                    />
                    <StaticImage
                        className="main-image"
                        src="../images/girl-sitting.png"
                        placeholder="none"
                        quality={100}
                        alt="girl meditating"
                    />
                </div>
            </StyledHomeHero>
        </>
    );
};
