import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import { Link } from 'gatsby';

const StyledMembershipPanels = styled.div`
    .text-container {
        padding: 0em 1em;
    }
    .panel {
        padding: 0em 1em;
        z-index: 0;
        text-align: center;
        h3 {
            font-size: 24px;
            line-height: 37px;
            padding-bottom: 0.5em;
            color: ${colors.pink};
        }
        button {
            margin: 1em auto;
        }
        .circle {
            margin: 1.5em auto;
            background: radial-gradient(142.84% 80.36% at 0% 100%, #00526f 0%, #218fb6 100%);
            border-radius: 300px;
            width: 250px;
            height: 250px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .panel-container {
        overflow-x: scroll;
    }
    .scroll-container {
        display: flex;
    }
    button {
        margin: 2em 0em 1.5em;
    }
    @media ${device.tablet} {
        padding-top: 4em;
        .text-container {
            max-width: 720px;
            display: flex;
            margin: 0 auto;
            justify-content: space-between;
            .text-right {
                margin-top: 2em;
                margin-left: 3em;
                margin-bottom: 2em;
                width: 430px;
            }
            .text-left {
                min-width: 260px;
            }
        }
    }
    @media ${device.laptop} {
        padding-top: 10em;
        .panel {
            margin: 0em 1em;
            p {
                width: 250px;
            }
        }
        .text-container {
            max-width: 1120px;
            display: flex;
            margin: 0 auto;
            justify-content: space-between;
            .text-right {
                margin-top: 2em;
                margin-left: 8em;
                width: 540px;
                z-index: 2;
            }
            .text-left {
                min-width: 460px;
            }
        }
        .panel-container {
            overflow-x: hidden;

            display: flex;
            justify-content: space-around;
            margin: 0 auto;
            max-width: 1340px;
        }
    }
    @media ${device.laptopL} {
        padding-top: 15em;
        .panel {
            margin: 0em 1.5em;
        }
    }
`;

export const MembershipPanels: React.FC = () => {
    return (
        <StyledMembershipPanels>
            <div className="text-container">
                <div className="text-left">
                    <h2>
                        <span>Membership Plans,</span>
                        <br />
                        Regardless of Insurance
                    </h2>
                </div>
                <div className="text-right">
                    <p>
                        Bloom La Vie Health is an innovative primary care medical home that provides
                        all-inclusive care with an affordable monthly subscription, regardless of
                        insurance coverage. Personalized care is achieved through a holistic,
                        integrative/functional medicine approach with each member.
                    </p>
                </div>
            </div>
            <div className="panel-container">
                <div className="scroll-container">
                    <div className="panel">
                        <div className="circle">
                            <StaticImage
                                src="../images/svgs/family.svg"
                                placeholder="none"
                                quality={100}
                                alt="white arrow point right"
                            />
                        </div>
                        <h3>Family Medicine</h3>
                        <p>
                            Many families have to schedule different medical appointments each month
                            because everyone has a different doctor who practices in a different
                            office, often in a different area across town. There is a way to
                            streamline your family care so that everyone is scheduled at the same
                            office. At Bloom La Vie Health in Kansas City, MO, not only are we an
                            office you can trust, but we are a Family Medicine provider who can care
                            for the health of everyone in your family under one roof.
                        </p>
                        <Link to="/services/family-medicine">
                            <Button color="blue-medium">
                                <span>Read More</span>{' '}
                                <StaticImage
                                    src="../images/svgs/arrow-right-blue-light.svg"
                                    placeholder="none"
                                    quality={100}
                                    alt="white arrow point right"
                                />
                            </Button>
                        </Link>
                    </div>
                    <div className="panel">
                        <div className="circle">
                            <StaticImage
                                src="../images/svgs/sharing.svg"
                                placeholder="none"
                                quality={100}
                                alt="white arrow point right"
                            />
                        </div>
                        <h3>Healthcare Sharing</h3>
                        <p>
                            Healthcare sharing or Healthsharing ministries aka medical cost sharing
                            is beneficial when combined with a Direct Primary Care practice.
                            Healthcare sharing or Healthsharing ministries have many advantages for
                            patients willing to be responsible for their own health care and cost
                            decisions.
                        </p>
                        <Link to="/plans/health-sharing">
                            <Button color="blue-medium">
                                <span>Read More</span>{' '}
                                <StaticImage
                                    src="../images/svgs/arrow-right-blue-light.svg"
                                    placeholder="none"
                                    quality={100}
                                    alt="white arrow point right"
                                />
                            </Button>
                        </Link>
                    </div>
                    <div className="panel">
                        <div className="circle">
                            <StaticImage
                                src="../images/svgs/women.svg"
                                placeholder="none"
                                quality={100}
                                alt="white arrow point right"
                            />
                        </div>
                        <h3>Women’s Health</h3>
                        <p>
                            If you’re looking for an office that can handle all of your women’s
                            health needs, look no further. At Bloom La Vie Health in Kansas City,
                            MO, we offer an array of different women’s services that can meet every
                            woman’s needs from routine check-up exams to helping you find the right
                            birth control.
                        </p>
                        <Link to="/services/womens-health">
                            <Button color="blue-medium">
                                <span>Read More</span>{' '}
                                <StaticImage
                                    src="../images/svgs/arrow-right-blue-light.svg"
                                    placeholder="none"
                                    quality={100}
                                    alt="white arrow point right"
                                />
                            </Button>
                        </Link>
                    </div>
                    <div className="panel">
                        <div className="circle">
                            <StaticImage
                                src="../images/svgs/body.svg"
                                placeholder="none"
                                quality={100}
                                alt="white arrow point right"
                            />
                        </div>
                        <h3>Body, Mind, & Soul</h3>
                        <p>
                            Your body, mind & soul all work together to help you feel vibrant,
                            healthy, and whole. If your physical body is unhealthy, it affects your
                            mind and your soul. Similarly, if your soul or mind lacks balance and
                            stability, your body will be equally affected. At Bloom La Vie in Kansas
                            City, MO, we offer several different services to help treat your entire
                            person: body, mind & soul.
                        </p>
                        <Link to="/services/body-mind-soul/">
                            <Button color="blue-medium">
                                <span>Read More</span>{' '}
                                <StaticImage
                                    src="../images/svgs/arrow-right-blue-light.svg"
                                    placeholder="none"
                                    quality={100}
                                    alt="white arrow point right"
                                />
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </StyledMembershipPanels>
    );
};
