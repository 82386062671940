import { HomeHero } from '@/HomeHero';
import { MembershipPanels } from '@/Membership-Panels';
import { MembershipPlans } from '@/Membership-Plans';
import { HomeAbout } from '@/HomeAbout';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Seo } from '@/Seo';
import { Button } from '@/layout/StyledComponents';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import { colors, device } from '../components/layout/GlobalStyles';

const bois = {
    nedeau: {
        title: 'Our Founder',
        name: 'Christine Nedeau, MD, FAAFP',
        bio: (
            <>
                “Health is so precious and fragile, and it’s often taken for granted until disease
                happens, but fortunately, the human body and mind are resilient! I teach people how
                to prevent, reverse, (and daresay, cure) their medical conditions. It’s a privilege
                to guide my patients down a new path towards longevity!” Dr. Christine Nedeau, MD,
                FAAFP is a board-certified family physician and the founder and CEO of Bloom La Vie
                Health.{' '}
            </>
        ),

        button: (
            <Link to="/waitlist">
                <Button color="blue-light">
                    <span>Add Name to Waitlist</span>{' '}
                    <StaticImage
                        src="../images/svgs/arrow-right-blue-light.svg"
                        placeholder="none"
                        quality={100}
                        alt="blue arrow point right"
                    />
                </Button>
            </Link>
        ),
    },
};

const Home: Page = () => {
    // const blogs = data.allMdx.nodes;
    return (
        <main>
            <DefaultLayout title="NO_DISPLAY" home>
                <Seo
                    title="Bloom La Vie Health Primary Care Practice | Bloom La Vie Health"
                    description="Natural Medicine Can Help Us Identify the Root Causes of Your Illness. Contact Us Now! Visit us today to Help Heal Your Body Naturally."
                />
                <HomeHero />
                <MembershipPanels />
                <HomeAbout {...bois.nedeau} />
                <MembershipPlans />
            </DefaultLayout>
        </main>
    );
};

export default Home;
