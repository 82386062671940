import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import { Link } from 'gatsby';

interface Prop {
    reverse?: boolean;
}

const StyledAbout = styled.div<Prop>`
    padding: 2em 1em;
    position: relative;

    .photo {
        box-shadow: 32px 32px 64px rgba(3, 93, 125, 0.2), -32px -32px 64px rgba(3, 93, 125, 0.2);
        border-radius: 32px;
    }
    h2 {
        border-top: 2px solid ${colors.blueLight};
        margin: 1em 0em;
        padding-top: 0.75em;
    }
    p {
        margin: 1em 0em;
    }
    @media ${device.tablet} {
        display: flex;
        flex-direction: column;
        align-items: center;

        .photo {
            margin: 0 auto;
        }
        .text-container {
            padding: 0em 4em;
        }
        button {
            margin-top: 2em;
        }
    }
    @media ${device.laptop} {
        display: flex;
        flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
        padding: 0em 4em 2em;

        justify-content: space-around;
        .text-container {
            position: relative;
            bottom: 20px;
        }
        .photo {
            width: 450px;
            height: 350px;
        }
    }
    @media ${device.laptopL} {
        display: flex;

        justify-content: space-around;
        h2 {
            border-top: none;
        }
        .text-container {
            padding: 0em 2em;
        }
        .photo {
            margin-right: 2em;
            width: 600px;
            height: 450px;
        }
    }
    @media ${device.desktop} {
        width: 1542px;
        margin: 7em auto;
        padding-bottom: 8em;
    }
`;

const WaveContainer = styled.div`
    position: relative;
    .wave {
        position: absolute;
        bottom: -5em;
    }
    @media ${device.tablet} {
        .wave {
            width: 100vw;
            padding-top: 5em;
            margin-top: -10em;
            bottom: -14em;
        }
    }
`;

interface Props {
    title: string;
    bio: string;
    button: any;
    name: string;
    reverse?: boolean;
}

export const HomeAbout: React.FC<Props> = ({ reverse, title, name, bio, button }) => {
    return (
        <WaveContainer>
            <StyledAbout reverse={reverse}>
                <div>
                    <StaticImage
                        className="photo"
                        src="../images/doctor.jpg"
                        placeholder="none"
                        quality={100}
                        alt="doctor Nedeau"
                    />
                </div>
                <div className="text-container">
                    <h2>
                        <span>{title}</span>
                        <br />
                        {name}
                    </h2>
                    <p>{bio}</p>
                    {button}
                </div>
            </StyledAbout>
            {title === 'Our Founder' ? (
                <StaticImage
                    className="wave"
                    src="../images/svgs/wave.svg"
                    placeholder="none"
                    quality={100}
                    alt="three shaded blue wave"
                />
            ) : null}
        </WaveContainer>
    );
};
